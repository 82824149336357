.light-mode {
    --background: #fff;
    --text-primary: #3c3c3c;
    --text-secondary: #787878;
    --accent: #d6f1ec;
    --hover-color: #4382ba;
    --hover-text: #fff;
}

.dark-mode {
    --background: #1e1e1e;
    --text-primary: #fff;
    --text-secondary: #c8c8c8;
    --accent: #2a3f3a;
    --hover-color: #3d7eb8;
    --hover-text: #fff;
}

body {
    background-color: var(--background);
    color: var(--text-primary);
}

div {
    transition: background-color 0.1s, color 0.1s;
}

.section-name {
    color: var(--text-secondary);
    flex: 1;
    text-align: center;
    margin: auto;
    font-weight: 500;
    padding: 1em;
}

.section a {
    text-decoration: none;
    color: inherit;
    background-color: var(--accent);
    transition: background-color 0.2s, color 0.2s;
    padding: 0.2em;
    border-radius: 3px;
}

.section-name a:hover {
    background-color: var(--hover-color);
    color: var(--hover-text);
}

/* Other styles */
.main {
    width: 900px;
    margin: 0 auto;
    padding: 2em;
    margin-top: 1em;
}

.section {
    display: flex;
    margin-bottom: 0em;
}

.section-content {
    flex: 4;
    font-weight: 450;
}

p {
  line-height: 1.3;
}

/* gpt generated styles */
button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
}

.theme-button {
  margin-left: auto; /* Pushes the button to the right */
}


.blog-title {
  color: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.blog-title:hover {
  color: var(--hover-color);
}

